// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';

Rails.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactRailsUJS = require('react_ujs');
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
